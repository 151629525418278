@import '../../styles/mixins.scss';

.contactUs {
    padding: 6rem 130px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 100%;
    max-width: var(--container);
    margin: 0 auto;

    @include desktop {
        padding: 5rem 130px;
        max-width: var(--container-md);
    }

    @include large {
        flex-direction: column;
        padding: 4rem 2rem;
        gap: 2rem;
    }

    h4 {
        color: #959595;
        font-size: 1.6rem;
        font-weight: 400;

        @include desktop {
          font-size: 1.4rem;
        }

        @include large {
            text-align: center;
            font-size: 1rem;
        }

        strong {
            color: #ffffff;
            font-weight: 500;

            @include large {
                display: flex;
                text-align: center;
                justify-content: center;
            }
        }
    }

    .button {
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.7rem;
        padding: 0.7rem 1.4rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        color: #ffffff;
        background-color: var(--color-primary);
        //background: -webkit-linear-gradient(45deg, var(--color-primary), var(--color-secondary));
        overflow: hidden;
        position: relative;
        transition: .3s transform ease-in-out;
        z-index: 0;
    
        &::after{
        background-color: var(--color-primary-alt);
        content: '';
        display: block;
        height: 100%;
        width: calc(100% + 4px);
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(calc(-100% - 4px), 0);
        transform-origin: center center;
        transition: .3s transform ease-out;
        z-index: -1;
        }
        
        &:hover, &:focus{
            color: var(--color-white);

            &::after {
                transform: translate(0, 0);
            }
        }

        @include desktop {
          font-size: 1rem;
        }

        @include large {
          font-size: 1rem;
        }
    
        svg {
            color: var(--color-white);
        }
    }
}
