@import './styles/mixins.scss';
//@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
//@import url('http://fonts.cdnfonts.com/css/calibre');
@import url('https://fonts.googleapis.com/css2?family=DotGothic16&family=Kaisei+Tokumin:wght@700&family=Poppins:wght@300;400;500;600;700;800;900&family=Rampart+One&family=Yuji+Syuku&family=Zen+Antique&display=swap');

:root {
  --color-primary: #fdc94f;
  --color-secondary: #9f6e6e;
  // --color-primary: #eb947e;
  // --color-secondary: #b960ee;
  --color-primary-alt: #dbae44;
  --color-black: #000000;
  --color-white: #ffffff;
  --color-light: #e6f2f3;
  --color-dark: #1a1a1a;
  --color-gray: #959595;

  //--font-base: "DM Sans", sans-serif;
  --font-base: "Poppins", sans-serif;
  --font-jp: 'Yuji Syuku', serif;

  --weight-semi-bold: 600;
  --weight-bold: 700;
  --weight-extra-bold: 800;

  --container-lg: 1920px;
  --container: 1400px;
  --container-md: 1200px;
  --container-sm: 900px;
}

* {
  -webkit-appearance: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;

  ::selection {
    color: white;
    background: black;
  }
}

body {
  font-family: var(--font-base);
  font-size: 1.4rem;
  color: var(--color-black);
  background-color: var(--color-white);

  @include desktop {
    font-size: 1.2rem;
  }

  @include large {
    font-size: 1rem;
  }

  a {
    color: var(--color-primary);
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }
}
