@import 'styles/mixins.scss';

.app {
    &__background {
        &--white {
            background-color: var(--color-white);
        }
        &--light {
            background-color: var(--color-light);
        }
        &--dark {
            background-color: var(--color-dark);
        }
        &--gray {
            background-color: var(--color-dark);
        }
        &--transparent {
          background-color: transparent;
      }
    }
    .container {
        padding: 10rem 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        width: 100%;
        max-width: var(--container);
        margin: 0 auto;
    
        @include desktop {
            padding: 6rem 130px;
            max-width: var(--container-md);
        }
    
        @include large {
            padding: 5rem 2rem;
        }
    }
}

.app__works {
    .app__background {
        &--dark {
            background-color: var(--color-dark );
        }
    }
}

