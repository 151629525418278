/* ===== Media queries ===== */
$phone: 480px;
$tablet: 768px;
$large: 1024px;
$desktop: 1600px;

@mixin phone {

  @media only screen and (max-width: $phone) {
    @content;
  }
}

@mixin tablet {

  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin large {

  @media only screen and (max-width: $large) {
    @content;
  }
}

@mixin desktop {

  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin textGradient {
  //background: -webkit-linear-gradient(45deg, var(--color-primary), #b960ee);
  background: -webkit-linear-gradient(45deg, var(--color-primary), var(--color-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
