@import '../../styles/mixins.scss';

header {
    padding-top: 65px;
    
    h1 {
        text-align: center;
        padding: 6rem 2rem;
        font-size: 3.5rem;
        color: var(--color-black);
        z-index: 0;
        text-transform: uppercase;
        font-weight: var(--weight-extra-bold);

        @include desktop {
          font-size: 2.5rem;
        }

        @include large {
            padding: 4rem 2rem;
            font-size: 1.8rem;
        }
    }
}
