@import '../../styles/mixins.scss';

.intro {
  //background-color: var(--color-black);
  //height: 40vh;
  //padding: 10rem 0;
  //padding-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: var(--container);
  margin: 0 auto;
  z-index: 0;
  gap: 10rem;
  padding: 0 130px;
  padding-bottom: 10rem;
  padding-top: 10rem;

  @include desktop {
    max-width: var(--container-md);
    padding-bottom: 6rem;
    padding-top: 6rem;
    gap: 6rem;
  }

  @include large {
    flex-direction: column;
    padding: 5rem 0;
    padding-top: 0;
    gap: 0;
  }

  &--unkai {
    width: 100%;
    position: absolute;
    font-size: 10rem;
    font-weight: 800;
    color: var(--color-black);
    opacity: .1;
    top: -6rem;
  }

   > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }

  video {
    object-fit: cover;
    height: 300px;
    width: 300px;
    //border-radius: 50%;
    //box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    //transform: translateX(130px);
    z-index: 1;
    border-radius: 4.5rem;

    @include desktop {
      height: 250px;
      width: 250px;
    }

    @include large {
      height: 225px;
      width: 225px;
    }
  }

  &__intro {
    //background-color: var(--color-white);
    background-color: transparent;
    border-radius: 0.5rem;
    z-index: -1;

    @include large {
      padding: 2rem;
      padding-left: 2rem;
      padding-bottom: 0;
      padding-top: 4rem;
    }

    br {
      display: none;

      @include large {
        display: block;
      }
    }
    
    h2 {
      display: inline-block;
      position: relative;
      font-size: 2rem;
      font-weight: var(--weight-extra-bold);
      color: var(--color-black);
      margin-bottom: 1.4rem;
      text-transform: uppercase;

      @include desktop {
        font-size: 1.8rem;
      }

      @include large {
        font-size: 1.4rem;
      }

      strong {
        color: var(--color-primary);
        @include textGradient;
      }
    }
    
    p {
      font-size: 2.2rem;
      color: #222222;

      @include desktop {
        font-size: 1.8rem;
      }

      @include large {
        font-size: 1.2rem;
      }
    }
  }
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
