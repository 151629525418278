@import '../../styles/mixins.scss';

.navbar {
  width: 100%;
  height: 65px;
  position: fixed;
  z-index: 2;
  color: var(--color-black);
  transition: background-color 0.15s ease-in-out;

  ul.navbar__links  {
    li a {
      color: var(--color-black);
    }
  }

  &--transparent {
    background-color: transparent;
    color: var(--color-white);

    div.navbar__logo, select { 
      color: var(--color-white);
    }

    select option {
      color: var(--color-black);
    }

    ul.navbar__links  {
      li a {
        color: var(--color-white);
      }
    }

    button svg path {
      stroke: var(--color-white);
    }

    .navbar__links #language {
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    }
  }

  &--white {
    background-color: var(--color-white);
    color: var(--color-black);
    background-color: #ffffffd9;
    color: var(--color-black);
    backdrop-filter: blur(16px) saturate(5);


    div.navbar__logo { 
      color: var(--color-black);
    }

    ul.navbar__links  {
      li a {
        color: var(--color-black);
      }
    }
  }

  &__container {
    height: 65px;
    max-width: var(--container-lg);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;

    @include large {
      padding: 1rem 2rem;
    }
  }

  > * {
    flex: 1;
  }

  &__logo {
    color: var(--color-white);
    font-size: 2.4rem;
    text-transform: uppercase;
    font-weight: var(--weight-extra-bold);
    letter-spacing: -5px;
    margin-right: auto;
    display: flex;
    align-items: center;

    img {

      @include desktop {
        height: 20px;
      }

      @include large {
        height: 20px;
      }
    }
  }

  &__links  {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    @include large {
      display: none;
    }

    li {
      list-style-type: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 4px;
        border-bottom: 4px solid var(--color-primary);
        transform: scaleX(0);
        transform-origin: 100% 100%;
        transition: transform .6s;
        transition-timing-function: cubic-bezier(.39,.575,.28,.995);
      }
      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: 0 0;
        }
      }

      svg {
        margin-right: 0.2rem;
        height: 22px;
      }

      a {
        position: relative;
        color: var(--color-white);
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: var(--weight-bold);
        text-decoration: none;

        @include desktop {
          font-size: 1rem;
        }
      }
    }

    #language {
      border: 0;
      background-color: transparent;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: var(--weight-bold);
      font-family: var(--font-base);
      outline: none;
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
      background-position: 100% center !important;
      appearance: none !important;
      padding-right: 0.7rem !important;
      cursor: pointer;

      @include desktop {
        font-size: 1rem;
      }
    }
  }

  &__mobile {
    margin-left: auto;
    text-align: end;
    display: none;

    @include large {
      display: flex;
      justify-content: flex-end;
    }

    nav {
      position: initial;
      top: 0;
      right: 0;
      bottom: 0;
      height: 23px;
        
      button {
        outline: none;
        border: none;
        user-select: none;
        cursor: pointer;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: auto;
        height: auto;
        background: transparent;
        margin-top: 4px;

        @include large {
          margin-top: 5px;
        }
      }
        
      ul,
      li {
        margin: 0;
        padding: 0;
      }
      
      ul {
        background-color: var(--color-white);
        padding: 2rem;
        padding-top: calc(55px + 2rem);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: -1;

        li {
          list-style: none;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          justify-items: center;
          cursor: pointer;
          gap: 0.2rem;
  
          a {
            color: var(--color-black);
            font-size: 1.4rem;
            text-transform: uppercase;
            font-weight: var(--weight-bold);
            text-decoration: none;
          }

          svg {
            font-size: 1.4rem;
          }
        }

        #language {
          -webkit-appearance: auto;
          border: 0;
          background-color: transparent;
          font-size: 1.2rem;
          text-transform: uppercase;
          font-weight: var(--weight-bold);
          font-family: var(--font-base);
          outline: none;
          color: var(--color-black);
          background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
          background-position: 100% center !important;
          appearance: none !important;
          padding-right: 0.7rem !important;
          cursor: pointer;
        }
      }
    }

    &--closed {
      nav ul {
        visibility: hidden;
      }
    }

    &--open {
      nav ul {
        visibility: visible;
      }
    }
  }
}
