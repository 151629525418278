@import '../../styles/mixins.scss';

.footer {
  width: 100%;
  flex-direction: row;
  background-color: var(--color-white);
  height: 100px;

  @include large {
    flex-direction: column;
    height: auto;
  }

  &__container {
    height: 100px;
    max-width: var(--container-lg);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    flex-direction: row;
    flex: 1 1;
    
    @include large {
      height: auto;
      padding: 2rem;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 2rem;
    }
  }

  &__copyright {
    color: var(--color-gray);
    font-size: 1.2rem;

    @include desktop {
      font-size: 1rem;
    }
  }

  &__navbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 2rem;

    @include large {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
    }

    li {
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-items: center;
      cursor: pointer;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 4px;
        border-bottom: 4px solid var(--color-primary);
        transform: scaleX(0);
        transform-origin: 100% 100%;
        transition: transform .6s;
        transition-timing-function: cubic-bezier(.39,.575,.28,.995);

        @include large {
          display: none;
        }
      }
      &:hover {
        &:after {
          transform: scaleX(1);
          transform-origin: 0 0;
        }
      }

      &:last-child {
        display: none;
        gap: 0.2rem;

        svg {
          color: var(--color-black);
          height: 22px;
        }

        @include large {
          display: flex;
        }
      }

      a {
        color: var(--color-black);
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: var(--weight-bold);
        text-decoration: none;

        @include desktop {
          font-size: 1rem;
        }
      }
    }

    #language {
      -webkit-appearance: auto;
      border: 0;
      background-color: transparent;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: var(--weight-bold);
      font-family: var(--font-base);
      outline: none;
      color: var(--color-black);
      background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
      background-position: 100% center !important;
      appearance: none !important;
      padding-right: 0.7rem !important;
      cursor: pointer;

      @include desktop {
        font-size: 1rem;
      }
    }
  }
}

.app__footer-cards {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 4em 2rem 2rem;

  .app__footer-card {
    min-width: 290px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
    background-color: #fef4f5;

    transition: all 0.3s ease-in-out;

    img {
      width: 40px;
      height: 40px;
      margin: 0 0.7rem;
    }

    p {
      font-weight: 500;
    }
    a {
      text-decoration: none;
      font-weight: 500;
    }
    &:hover {
      box-shadow: 0 0 25px #fef4f5;
    }

    @media screen and (max-width: 450px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.app__footer-cards .app__footer-card:last-child {
  background-color: #f2f7fb;
}

.app__footer-cards .app__footer-card:last-child:hover {
  box-shadow: 0 0 25px #f2f7fb;
}

.app__footer-form {
  width: 60%;
  flex-direction: column;
  margin: 1rem 2rem;

  div {
    width: 100%;
    margin: 0.75rem 0;
    border-radius: 10px;
    cursor: pointer;
    background-color: var(--primary-color);

    transition: all 0.3s ease-in-out;

    input,
    textarea {
      width: 100%;
      padding: 0.95rem;
      border: none;
      border-radius: 7px;
      background-color: var(--primary-color);

      font-family: var(--font-base);
      color: var(--secondary-color);
      outline: none;
    }

    textarea {
      height: 170px;
    }

    &:hover {
      box-shadow: 0 0 25px var(--primary-color);
    }
  }

  button {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: none;
    background-color: var(--secondary-color);

    font-weight: 500;
    color: var(--white-color);
    outline: none;
    margin: 2rem 0 0 0;
    font-family: var(--font-base);

    transition: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    cursor: pointer;

    &:hover {
      background-color: #2430af;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 1rem 0;
  }
}
