@import '../../styles/mixins.scss';

.services {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: var(--container);
  margin: 0 auto;
  z-index: 0;
  gap: 10rem;
  padding: 10rem 130px;
  padding-top: 0;

  @include desktop {
    max-width: var(--container-md);
    gap: 6rem;
  }

  @include large {
    flex-direction: column-reverse;
    padding: 5rem 0;
    padding-top: 0;
  }

  &--animation {
    width: 100vw;
    position: absolute;
    font-size: 10rem;
    font-weight: 800;
    color: var(--color-black);
    opacity: .1;
    top: 4.5rem;
  }

  video {
    object-fit: cover;
    height: 400px;
    width: 400px;
    border-radius: 0.5rem;
    //transform: translateX(-130px);
    z-index: 1;

    @include desktop {
      height: 300px;
      width: 300px;
    }

    @include large {
      transform: none;
    }
  }

  &__intro {
    background-color: #fff;
    border-radius: 0.5rem;
    z-index: -1;

    @include large {
      padding: 2rem;
      padding-right: 2rem;
      padding-bottom: 0;
      padding-top: 0;
    }

    h2 {
      display: inline-block;
      position: relative;
      font-size: 2rem;
      font-weight: var(--weight-extra-bold);
      color: var(--color-black);
      margin-bottom: 1.4rem;
      text-transform: uppercase;

      @include desktop {
        font-size: 1.8rem;
      }

      @include large {
        font-size: 1.4rem;
      }

      strong {
        color: var(--color-primary);
        @include textGradient;
      }
    }
  }
}

.app__services {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
