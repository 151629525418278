@import '../../styles/mixins.scss';

.project {

  &__header {
    position: relative;
    //top: 65px;
    top: 0;
    width: 100%;
    height: 50vh;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--color-white);
    background-color: var(--color-light);
    background-position: top center;

    @include large {
      height: 45vh;
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }

    .mantine-Skeleton-root {
      display: none;

      @include large {
        display: block;
      }
    }

    h1 {
      text-align: center;
      padding: 6rem 2rem;
      font-size: 3.5rem;
      color: var(--color-white);
      z-index: 1;
      text-transform: uppercase;
      font-weight: var(--weight-extra-bold);
      max-width: var(--container);
      padding: 1rem 130px;

      @include desktop {
        padding: 1rem 224px;
        font-size: 2.2rem;
      }

      @include large {
        padding: 4rem 2rem;
        font-size: 1.6rem;
      }
    }
  }

  &__content {
    padding: 10rem 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 100%;
    max-width: var(--container);
    margin: 0 auto;
    margin-top: -245px;

    @include desktop {
      max-width: var(--container-md);
      padding: 6rem 130px;
      margin-top: -165px;
    }

    @include large {
      padding: 5rem 1rem;
      gap: 3.5rem;
      margin-top: -125px;
    }

    .skeleton-lines {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;
    }

    p {
      color: var(--color-black);
      padding: 0 1rem;
    }

    img {
      width: 100%;
      padding: 0 1rem;
    }

    .button {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.7rem;
      padding: 0.7rem 1.4rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      border: 4px solid var(--color-black);
      color: var(--color-black);
      margin-top: 6rem;
      //background: -webkit-linear-gradient(45deg, var(--color-primary), var(--color-secondary));
      overflow: hidden;
      position: relative;
      transition: .3s transform ease-in-out, color .45s cubic-bezier(.785,.135,.15,.86);
      z-index: 0;

      @include large {
        transition: .3s transform ease-in-out;
      }
    
      &::after{
        background-color: var(--color-black);
        content: '';
        display: block;
        height: 100%;
        width: calc(100% + 4px);
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(calc(-100% - 4px), 0);
        transform-origin: center center;
        transition: .3s transform ease-out;
        z-index: -1;
      }

      svg {
        transition: color .45s cubic-bezier(.785,.135,.15,.86);
      }
        
      &:hover, &:focus{
        color: var(--color-white);

        svg {
          color: var(--color-white);
        }

        &::after {
          transform: translate(0, 0);
        }
      }

      @include desktop {
        margin-top: 2rem;
        font-size: 1rem;
      }

      @include large {
        font-size: 1rem;
        margin-top: 1.5rem;
      }

      svg {
        font-size: 1.2rem;
        color: var(--color-black);
      }
    }
  }
}

.mantine-Skeleton-root {
  @include large {
    max-height: 200px;
  }
}