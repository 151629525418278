@import '../../styles/mixins.scss';

.projects {
  background-color: var(--color-white);
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  width: 100%;
  //max-width: 1920px;
  //padding: 0 1rem;
  margin: 0 auto;

  @include desktop {
    padding: 0;
  }

  @include large {
    //padding: 0 1rem;\
    gap: 3.5rem;
  }

  &.projectsPage {
    background-color: var(--color-white);
    //padding: 10rem 0;

    @include desktop {
      //padding: 6rem 0;
    }
    
    @include large {
      //padding: 5rem 1rem;
      gap: 3.5rem;
    }

    .button--mobile {
      display: none;
    }
  }

  &__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h2 {
      display: block;
      position: relative;
      text-align: center;
      font-size: 2rem;
      font-weight: var(--weight-bold);
      color: #ffffff;
      line-height: 1;
      z-index: 1;
      text-transform: uppercase;
  
      @include desktop {
        font-size: 1.8rem;
      }

      @include large {
        font-size: 1.4rem;
      }
  
      &:after {
        //content: " ";
        position: absolute;
        background: var(--color-primary);
        z-index: -1;
        top: 0px;
        right: -5px;
        width: 30px;
        height: 30px;
        transform: rotate(-35deg);
        border-radius: 50%;
      }
  
      span {
        color: var(--color-primary);
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
  }

  &--hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;

    &::after {
      content: '';
      display: flex;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
    }

    @include large {
      opacity: 1;
      background-color: transparent;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: 100vh;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    @media (min-aspect-ratio: 16/9) {
      video {
        height: 56.25vw;
      }
    }
    @media (max-aspect-ratio: 16/9) {
      video {
        width: 177.78vh;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      z-index: 1;
    }
  
    div {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      margin: 1rem;
      font-family: var(--font-base);
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 1;

      @include desktop {
        width: 60px;
        height: 60px;
      }

      @include large {
        display: none;
      }
  
      svg {
        width: 50%;
        height: 50%;
        color: var(--white-color);
      }
    }
  }

  &__filter {
    display: none !important; //TO DO
    //display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  
    &--item {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7rem 1.4rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 700;
      background-color: var(--color-light);
      color: var(--color-black);
      cursor: pointer;
      transition: all 0.3s ease;
  
      &:hover {
        opacity: .5;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
      }
      
    }

    &--item-active {
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }

  &__portfolio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    //gap: 1rem;
    //padding: 0 1rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin: 0 auto;
    position: relative;

    @include large {
      grid-template-columns: 1fr;
      margin-top: 0;
      padding: 0;
    }

    .mantine-Skeleton-root {
      @include large {
        max-height: initial;
      }
    }
  
    &--item {
      width: 100%;
      height: 100%;
      flex-direction: column;
      background-color: #fff;
      color: #000;
      cursor: pointer;
      transition: all 0.3s ease;
      width: auto;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover; /* use the one you need */
  
      // &:hover {
      //   box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
      // }
      @include large {
        aspect-ratio: 1;
      }
    }

    &--placeholder {
      background-color: var(--color-black);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 0 2rem;
      cursor: initial;
      overflow: hidden;
      position: relative;

      @include large {
        display: none;
      }

      img {
        will-change: transform; 
        animation: linear 4s rotate infinite;
        user-select: none;
        z-index: 1;
      }

      h4 {
        position: relative;
        font-size: 1.2rem;
        padding: 0;
        z-index: 1;
      }

      video {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        filter: contrast(0.85);
      }

      @keyframes rotate {
        0% { transform: rotateY(45deg); }
        100% { transform: rotateY(405deg); }
      }

      @keyframes noise {
        0% {
          transform: translateX(0px,0px); }
        10% {
          transform: translate(-100px, 100px);
        }
        20% {
          transform: translate(150px, -100px);
        }
        30% {
          transform: translate(-100px,100px);
        }
        40% {
          transform: translate(100px, -150px);
        }
        50% {
          transform: translate(-100px, 200px);
        }
        60% {
          transform: translate(-200px, -100px);
        }
        70% {
          transform: translateY(50px, 100px);
        }
        80% {
          transform: translate(100px, -150px);
        }
        90% {
          transform: translate(0px, 200px);
        }
        100% {
          transform: translate(-100px, 100px);
        }
      }
    }

    &--img {
      width: 100%;
      height: 350px;
      position: relative;
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      object-fit: cover; /* use the one you need */

      @include large {
        aspect-ratio: 1;
      }
    
      img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
        object-fit: cover; /* use the one you need */

        @include large {
          aspect-ratio: 1;
        }
      }
    }

    &--title {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      //top: 0;
      width: 100%;
      padding: 5rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      //background: linear-gradient(#0000009c 0, #e6f2f300 100%);
      font-size: 3.2rem;
      z-index: 1;
      // background-color: #ffffff40;
      // backdrop-filter: blur(16px);

      @include desktop {
        font-size: 2.2rem;
      }

      @include large {
        font-size: 1.8rem;
        padding: 2rem;
      }
    }
  }

  &__content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  
    h4 {
      margin-top: 1rem;
      line-height: 1.5;
  
      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }
  }

  .button {
    color: var(--color-black);
    border: 4px solid var(--color-black);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
    background-color: transparent;
    padding: 0.7rem 1.4rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    transition: .3s transform ease-in-out, color .45s cubic-bezier(.785,.135,.15,.86);
    z-index: 0;

    @include large {
      transition: .3s transform ease-in-out;
    }
  
    &::after{
      background-color: var(--color-black);
      content: '';
      display: block;
      height: 100%;
      width: calc(100% + 4px);
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(calc(-100% - 4px), 0);
      transform-origin: center center;
      transition: .3s transform ease-out;
      z-index: -1;
    }
      
    &:hover, &:focus{
      color: var(--color-white);

      &::after {
        transform: translate(0, 0);
      }
    }

    svg {
      color: var(--color-primary);
    }

    @include desktop {
      font-size: 1rem;
    }

    @include large {
      display: none;
    }

    &--mobile {
      display: none;

      @include large {
        display: flex;
        font-size: 1rem;
      }
    }
  }
}
