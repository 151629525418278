@import '../../styles/mixins.scss';

.about {
    background-color: var(--color-white);
    padding: 10rem 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 100%;
    max-width: var(--container);
    margin: 0 auto;

    @include desktop {
      padding: 6rem 130px;
      max-width: var(--container-md);
    }

    @include large {
      font-size: 1rem;
      padding: 5rem 2rem;
      gap: 3.5rem;
    }

    > div > div > div {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      strong {
        display: flex;
      }

      p br {
        display: none;
      }
    }

    .map {
      width: 100%;
      height: 100%;
      aspect-ratio: 16/9;
      filter: grayscale(100%);

      @include large {
        aspect-ratio: 1;
      }
    }
}
