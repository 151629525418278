@import '../../styles/mixins.scss';

.mantine-Notification-root {
    height: 6rem;
    padding: 1rem;

    @include large {
        height: 4rem;
    }

    .mantine-Notification-title {
        color: var(--color-black);
        font-family: var(--font-base);
        font-size: 1rem;

        @include large {
            font-size: 1rem;
        }
    }
    .mantine-Notification-icon {
        background-color: transparent;
        svg {
            font-size: 2.4rem;
            color: #40c057;
        }
    }
}

.career {
    background-color: var(--color-white);
    padding: 10rem 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    width: 100%;
    max-width: var(--container);
    margin: 0 auto;

    @include desktop {
        padding: 6rem 130px;
        max-width: var(--container-md);
    }

    @include large {
        padding: 5rem 2rem;
        gap: 3.5rem;
    }

    > div {
        width: 100%;
    }

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-top: 10rem;

        @include desktop {
          margin-top: 6rem;
        }

        @include large {
            margin-top: 5rem;
        }

        > div {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

       input, textarea, select {
           font-family: var(--font-base);
           color: var(--color-black);
           font-size: 1rem;
           width: 100%;
           padding: 1.2rem 1.4rem;
           min-height: 52px;
           background-color: var(--color-light);
           border: 0;
           outline: 0;
           border-radius: 0;
       }

       select {
            background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
            background-color: var(--color-light);
            background-position: calc(100% - 0.75rem) center !important;
            -moz-appearance:none !important;
            -webkit-appearance: none !important; 
            appearance: none !important;
            padding-right: 2rem !important;
            cursor: pointer;
       }

       input[type="file"] {
            background-color: transparent;
            padding: 0;
            min-height: initial;
       }

       button {
            border: 0;
            width: fit-content;
            text-decoration: none;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.7rem;
            padding: 0.7rem 1.4rem;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 700;
            color: #ffffff;
            background-color: var(--color-primary);
            //background: -webkit-linear-gradient(45deg, var(--color-primary), var(--color-secondary));
            overflow: hidden;
            position: relative;
            transition: .3s transform ease-in-out;
            z-index: 0;
        
            &::after{
            background-color: var(--color-primary-alt);
            content: '';
            display: block;
            height: 100%;
            width: calc(100% + 4px);
            position: absolute;
            left: 0;
            top: 0;
            transform: translate(calc(-100% - 4px), 0);
            transform-origin: center center;
            transition: .3s transform ease-out;
            z-index: -1;
            }
            
            &:hover, &:focus{
                color: var(--color-white);

                &::after {
                    transform: translate(0, 0);
                }
            }

            &:hover {
                cursor: pointer;
            }

            @include large {
                font-size: 1rem;
            }
        
            svg {
                color: var(--color-white);
            }
       }

       label {
            text-transform: uppercase;
            font-size: 0.85rem;
            font-weight: 600;
            color: var(--color-black);
       }
    }
}
