@import '../../styles/mixins.scss';

.hero {
  //background: linear-gradient(#000 0, rgba(0,0,0,.8) 40%, rgba(0,0,0,.4) 70%, rgba(0,0,0,0) 100%);
  //background: linear-gradient(#00000063 0, #e6f2f300 100%);
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  overflow: hidden;
  //background: linear-gradient(#000 0, var(--color-black) 100%);

  @include desktop {
    height: 100vh;
  }

  img {
    position: absolute;
    width: 80%;
    max-width: 1920px;
    height: auto;
    opacity: 0.29;
    filter: grayscale(1);
    user-select: none;

    @include large {
      width: 250%;
    }

    svg path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;
      animation: dash 3s ease-in-out infinite; 
    }
  }

  .unkai {
    position: absolute;
    font-size: 50rem;
    font-weight: 800;
    text-align: center;
    color: var(--color-light);
    user-select: none;
    line-height: 0.9;

    @include desktop {
      font-size: 25rem;
    }

    @include large {
      font-size: 12rem;
      writing-mode: vertical-rl;
      text-orientation: upright;
      letter-spacing: 1.4rem;
      left: -50px;
    }
  }

  @keyframes dash {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  #background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
  }

  h1 {
    width: 100%;
    font-size: 4rem;
    text-align: left;
    color: var(--color-black);
    z-index: 0;
    //text-transform: uppercase;
    font-weight: var(--weight-bold);
    max-width: var(--container);
    padding: 0 130px;

    @include desktop {
      max-width: var(--container-md);
      font-size: 3rem;
    }

    @include large {
      padding: 0 2rem;
      font-size: 1.8rem;
    }

    &.centered {
      @include large {
        text-align: center;
      }
    }

    br {
      display: none;

      @include large {
        display: block;
      }
    }

    strong {
      // color: #eb947e;
      color: var(--color-primary);
      //@include textGradient;
    }
  }

  .scroll {
    position: absolute;
    bottom: 130px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 3px solid var(--color-primary);
    border-bottom: 3px solid var(--color-primary);
    -webkit-transform: rotate(-45deg); 
    transform: rotate(-45deg);
    -webkit-animation: scroll 2s infinite;
    animation: scroll 2s infinite;
    opacity: 0;
    box-sizing: border-box;

    @include desktop {
      bottom: 6rem;
    }

    @include large {
      bottom: 34px;
    }
  }
  .scroll:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .scroll:nth-of-type(2) {
    bottom: 145px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;

    @include desktop {
      bottom: 7rem;
    }

    @include large {
      bottom: 49px;
    }
  }
  .scroll:nth-of-type(3) {
    display: none;
    bottom: 160px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;

    @include desktop {
      display: none;
      bottom: 8rem;
    }

    @include large {
      bottom: 64px;
    }
  }
  @-webkit-keyframes scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes scroll {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.landing {
  position: absolute;
  margin: auto;
  display: block;
  width: 100%;
  height: 100vh;
  background: rgb(190, 231, 232);
  background: linear-gradient(0deg, #eb947e 0%, #101010d1 100%);
  overflow: hidden;
}

/* Background Mountains

Triangles made using transform rotate
width = 1.41 x height(padding-bottom) */

.tri {
  width: 30%;
  padding-bottom: 21%;
  position: relative;
  overflow: hidden;
}
.tri:before {
  content: "";
  position: absolute;

  width: 100%;
  height: 104%;
  background: rgb(254, 194, 178);
  background: linear-gradient(
    180deg,
    rgba(254, 194, 178, 1) 0%,
    rgba(235, 148, 126, 1) 55%
  );
  border-radius: 20px;

  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.small-mountains .tri:nth-child(1) {
  bottom: 0;
  position: absolute;
  left: -7%;
}

.small-mountains .tri:nth-child(2) {
  bottom: 0;
  position: absolute;
  left: 40%;
}

.small-mountains .tri:nth-child(3) {
  bottom: 0;
  position: absolute;
  right: -10%;
}

.tri2 {
  width: 48%;
  padding-bottom: 33%;
  position: relative;
  overflow: hidden;
}
.tri2:before {
  content: "";
  position: absolute;

  width: 100%;
  height: 104%;
  /* background: #726DA8; */
  background: rgb(254, 194, 178);
  background: linear-gradient(
    180deg,
    rgba(254, 194, 178, 1) 0%,
    rgba(235, 148, 126, 1) 55%
  );
  border-radius: 20px;

  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tall-mountains .tri2:nth-child(1) {
  bottom: 0;
  position: absolute;
  left: 0%;
}

.tall-mountains .tri2:nth-child(2) {
  bottom: 0;
  position: absolute;
  left: 14%;
}

.tall-mountains .tri2:nth-child(3) {
  bottom: 0;
  position: absolute;
  left: 50%;
}

/* Foreground mountains */

.tri3 {
  width: 19%;
  padding-bottom: 13%;
  position: relative;
  overflow: hidden;
}

.tri3:before {
  content: "";
  position: absolute;

  width: 100%;
  height: 104%;
  background: rgb(233, 243, 244);
  background: linear-gradient(
    180deg,
    rgba(233, 243, 244, 1) 0%,
    rgba(190, 231, 232, 1) 72%
  );
  border-radius: 18px;

  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tiny-mountains .tri3:nth-child(1) {
  bottom: 0;
  position: absolute;
  left: 9%;
}
.tiny-mountains .tri3:nth-child(2) {
  bottom: -3%;
  position: absolute;
  left: 31%;
}

.tiny-mountains .tri3:nth-child(3) {
  bottom: -7%;
  position: absolute;
  left: 42%;
}

.tiny-mountains .tri3:nth-child(4) {
  bottom: 0;
  position: absolute;
  left: 72%;
}

.tiny-mountains .tri3:nth-child(5) {
  bottom: -5%;
  position: absolute;
  left: 62%;
}
.ground {
  position: relative;
  height: 25%;
  background: rgb(255, 246, 202);
  background: linear-gradient(
    180deg,
    rgba(255, 246, 202, 1) 0%,
    rgba(255, 229, 164, 1) 72%
  );
}

#clouds {
  padding: 4vw 0;
  bottom: 0;
  position: absolute;
}

/* base of cloud */
.cloud {
  height: 4vw;
  width: 13vw;
  background: white;
  border-radius: 40vw;
  position: relative;
}

/* smaller circle within cloud */
.cloud:before {
  content: "";
  position: absolute;
  top: -2vw;
  height: 6vw;
  width: 6vw;
  left: 2vw;
  border-radius: 50%;
  background: white;
}

/* larger circle within cloud */
.cloud:after {
  content: "";
  position: absolute;
  height: 6vw;
  width: 6vw;
  top: -3vw;
  left: 5vw;
  border-radius: 50%;
  background: white;
}

#clouds .cloud:nth-child(1) {
  opacity: 0.7;
  animation: moveclouds 17s linear infinite;
  -webkit-animation: moveclouds 17s linear infinite;
  -moz-animation: moveclouds 17s linear infinite;
  -o-animation: moveclouds 17s linear infinite;
}
#clouds .cloud:nth-child(2) {
  left: 75%;
  opacity: 0.5;
  animation: moveclouds 22s linear infinite;
  -webkit-animation: moveclouds 22s linear infinite;
  -moz-animation: moveclouds 22s linear infinite;
  -o-animation: moveclouds 22s linear infinite;
}
#clouds .cloud:nth-child(3) {
  left: 50%;
  z-index: 1;
  animation: moveclouds 15s linear infinite;
  -webkit-animation: moveclouds 15s linear infinite;
  -moz-animation: moveclouds 15s linear infinite;
  -o-animation: moveclouds 15s linear infinite;
}
#clouds .cloud:nth-child(4) {
  left: 90%;
  opacity: 0.7;
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  -o-transform: scale(0.6);
  animation: moveclouds 16s linear infinite;
  -webkit-animation: moveclouds 16s linear infinite;
  -moz-animation: moveclouds 16s linear infinite;
  -o-animation: moveclouds 16s linear infinite;
}
#clouds .cloud:nth-child(5) {
  left: 100%;
  opacity: 0.4;
  z-index: 1;

  transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);

  animation: moveclouds 22s linear infinite;
  -webkit-animation: moveclouds 22s linear infinite;
  -moz-animation: moveclouds 22s linear infinite;
  -o-animation: moveclouds 22s linear infinite;
}
@keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
@-moz-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
@-o-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }
  100% {
    margin-left: -1000px;
  }
}
